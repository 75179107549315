import {HStack, VStack, Flex, Center, Button, Text, Link, useColorModeValue, Heading} from "@chakra-ui/react"
import {HiChip} from "react-icons/hi";
import {FaMemory} from "react-icons/fa";
import React, {useCallback, useEffect, useState} from "react";
import {ClipLoader, HashLoader, PuffLoader} from "react-spinners";
import StatusLabel from "./StatusLabel";
import {Deployment} from "../../../../../types";
import useInterval from "../../../../../hooks/useInterval";
import DeleteDeploymentButton from "../../DeleteDeploymentButton";


const InstanceCard = ({title, cpus, memory, description, selected, onClick, disabled}: {
    title: string,
    cpus: number,
    memory: number,
    description: string,
    selected: boolean,
    onClick: () => void,
    disabled?: boolean
}) => {
    const strongColor = "gray.800"; // useColorModeValue("gray.800", "white");
    const mediumColor = selected ? "white" : "gray.600"; // useColorModeValue("gray.600", "white");
    const weakColor = selected ? "white" : (disabled ? "gray.300" : "teal.300"); //useColorModeValue("teal.300", "white");

    const backgroundColor = selected ? (disabled ? "var(--chakra-colors-gray-400)" : "rgb(88, 210, 199)") : "rgba(255, 255, 255, 0.6)";

    return (
        <div style={{width: "300px"}}>
            <Center>
                <VStack style={{
                    borderRadius: "8px",
                    borderWidth: "1px",
                    padding: "15px 30px",
                    height: "180px",
                    width: "210px",
                    backgroundColor
                }} role={disabled ? "" : "button"} onClick={disabled ? () => {
                } : onClick}>
                    <HStack justify={"center"} style={{"width": "100%"}}>
                        <Center style={{width: "70px"}}>
                            <Text style={{marginLeft: "1px"}} fontSize={"5xl"} fontWeight={"bold"}
                                  color={mediumColor}>{cpus}</Text>
                        </Center>
                        <div>
                            <Center>
                                <Text fontWeight={"bold"} color={mediumColor}>CPUs</Text>
                            </Center>
                            <Center>
                                <Text color={weakColor}>
                                    <HiChip size={30}/>
                                </Text>
                            </Center>
                        </div>
                    </HStack>

                    <HStack justify={"center"} style={{"width": "100%"}}>
                        <Center style={{width: "70px"}}>
                            <Text style={{marginRight: "5px"}} fontSize={"4xl"} color={mediumColor}>{memory}</Text>
                        </Center>
                        <div>
                            <Center><Text fontWeight={"bold"} color={mediumColor}>GB</Text></Center>
                            <Center>
                                <Text color={weakColor}><FaMemory size={30}/></Text>
                            </Center>
                        </div>
                    </HStack>
                </VStack>
            </Center>
            <Center style={{marginTop: "10px"}}>
                <Text fontWeight={"bold"} color={strongColor} fontSize={20}>{title}</Text>
            </Center>
            <Center>
                <Text color={"gray.400"} textAlign={"center"} fontSize={15}>{description}</Text>
            </Center>
        </div>
    )
}


const Index = ({deployment, updateDeployment, reloadAccount}: {
    deployment: Deployment,
    reloadAccount: any,
    updateDeployment: (newDeployment: Deployment) => Promise<void>
}) => {
    let AZURE_TEMPLATE = `https%3A%2F%2Fcloud.epsio.io%2Fepsio-azure-arm.json%3Fdeployment_id=${deployment.id}%26db_port=3306%26vm_size=`;

    if (process.env.REACT_APP_ENV === "staging" || process.env.REACT_APP_ENV === "dev") {
        AZURE_TEMPLATE = `https%3A%2F%2Fstaging-cloud.epsio.io%2Fepsio-azure-arm.json%3Fdeployment_id=${deployment.id}%26db_port=3306%26vm_size=`;
    }

    const selectedInstance = deployment.instanceType !== null ? deployment.instanceType : "medium";
    const [azureTemplate, setAzureTemplate] = useState(`${AZURE_TEMPLATE}${selectedInstance}`);

    const updateInstance = (newInstance: string) => {
        setAzureTemplate(`${AZURE_TEMPLATE}${newInstance}`);
        updateDeployment({...deployment, instanceType: newInstance});
    }

    const deployARM = () => {
        window.open(`https://portal.azure.com/#create/Microsoft.Template/uri/${azureTemplate}`);
    }

    const styles = {
        surroundingTrueCenter: {
            flexGrow: 1,
            flexBasis: 0,
            overflow: "auto"
        }
    }

    return (
        <div style={{width: "100%", height: "100%"}}>
            <Flex style={{height: "100%"}} flexDirection={"column"} justifyContent={"space-evenly"}>
                <Flex justifyContent={"center"}>
                    <HStack spacing={4} style={{paddingBottom: "5px"}} justify={"space-evenly"}>
                        <InstanceCard onClick={() => updateInstance("starter")}
                                      selected={selectedInstance === "starter"}
                                      title={"Lightweight"} cpus={8} memory={32}
                                      description={"Typically good for databases with small to medium workloads"}/>
                        <InstanceCard onClick={() => updateInstance("medium")}
                                      selected={selectedInstance === "medium"}
                                      title={"Medium"} cpus={16} memory={64}
                                      description={"Typically good for databases with medium workloads"}/>
                        <InstanceCard onClick={() => updateInstance("power")}
                                      selected={selectedInstance === "power"}
                                      title={"Power"} cpus={32} memory={128}
                                      description={"Typically good for databases with medium to large workloads"}/>
                    </HStack>
                </Flex>

                <Flex justifyContent={"space-between"}>
                    <div style={{paddingTop: "40px", ...styles.surroundingTrueCenter, overflowY: "visible"}}>

                        <div style={{marginLeft: "20px", marginBottom: "10px", marginTop: "38px", display: "flex", justifyContent: "center"}}>
                            <div>

                                <Button onClick={deployARM}
                                        colorScheme={"blue"}
                                        style={{flex: 1, padding: "25px", marginRight: "5px", width: "243px"}}
                                        fontSize={"lg"}>Launch ARM</Button>
                                <div style={{marginTop: "20px", marginLeft: "-4px"}}>
                                    <StatusLabel waitingText={"Waiting for Epsio to say hello..."}
                                                 finishedText={"Epsio said hello! Finished."}
                                                 error={null}
                                                 timeout={false}
                                                 waiting={!deployment.epsioSaidHello}/>
                                </div>

                            </div>
                        </div>

                    </div>
                </Flex>

            </Flex>
            <div style={{position: "fixed", right: "55px", zIndex: 999, bottom: 10}}>
                <DeleteDeploymentButton variant={"simple"} deployment={deployment} reloadAccount={reloadAccount}/>
            </div>
        </div>
    )
}

export default Index;
