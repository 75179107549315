import {
  HStack,
  VStack,
  Flex,
  Center,
  Button,
  Text,
  Link,
  useColorModeValue,
  Heading,
  TabPanels,
  TabPanel,
  TabList,
  Tabs,
  Tab, Spacer
} from "@chakra-ui/react"
import {HiChip} from "react-icons/hi";
import {FaMemory, FaUbuntu} from "react-icons/fa";
import React, {useCallback, useEffect, useState} from "react";
import {ClipLoader, HashLoader, PuffLoader} from "react-spinners";
import StatusLabel from "./StatusLabel";
import {Deployment} from "../../../../../types";
import {CodeBlock, CopyBlock, codepen, tomorrowNightBright, dracula, github, Code} from "react-code-blocks";
import DeleteDeploymentButton from "../../DeleteDeploymentButton";


const Index = ({
                 deployment,
                 updateDeployment,
       reloadAccount
               }: { deployment: Deployment, reloadAccount: any, updateDeployment: (newDeployment: Deployment) => Promise<void> }) => {
  const version = `${deployment.deployerVersion}`;
  let envStr = `DEPLOYMENT_ID=${deployment.id} VERSION=${version}`;
  let cloudBucket = "https://epsio.storage.googleapis.com"
  if (parseInt(version.split(".")[0]) < 17) {
    cloudBucket = "https://epsio.s3.amazonaws.com"
  }
  if (process.env.REACT_APP_ENV === "staging") {
    cloudBucket = "https://epsio-staging.storage.googleapis.com"
    envStr += ` CLOUD_BUCKET=${cloudBucket} CLOUD_URL=https://staging-cloud.epsio.io`;
  }
  if (process.env.REACT_APP_ENV === "dev") {
    cloudBucket = "http://localhost:3000"
    envStr += ` CLOUD_BUCKET=${cloudBucket} CLOUD_URL=http://localhost:8000 EPSIO_DIR=/tmp/epsio SKIP_INSTALL=1`;
  }
  const bucketName = process.env.REACT_APP_ENV === "staging" ? "epsio-staging" : "epsio";
  let installationUrl = `${cloudBucket}/${version}/install.sh`;
  return (
      <div style={{width: "100%", maxWidth: "950px", height: "100%", marginTop: "30px"}}>

        <Flex style={{height: "60%"}} flexDirection={"column"}>
          <div style={{marginTop: "20px"}}>
            <Text color={"gray.500"} style={{marginBottom: "10px"}}>Run the following on a Ubuntu/Mac machine (ARM64 or
              x86_64)</Text>
            <CopyBlock
                customStyle={{padding: "12px"}}
                text={`curl ${installationUrl} | ${envStr} bash`}
                language={"bash"}
                showLineNumbers={false}
                theme={dracula}
            />
          </div>

          <div style={{marginTop: "20px", marginLeft: "16px", marginRight: "16px"}}>
            <Text color={"gray.500"}>
              Once you've installed Epsio successfully, Epsio will notify the cloud it has been installed, and you will
              automatically move to the next step.
            </Text>
            <div style={{marginTop: "20px", marginLeft: "-4px"}}>
              <StatusLabel waitingText={"Waiting for Epsio to say hello..."}
                           finishedText={"Epsio said hello! Finished."}
                           error={null}
                           timeout={false}
                           waiting={!deployment.epsioSaidHello}/>
            </div>
          </div>
          <div style={{position: "fixed", right: "55px", zIndex: 999, bottom: 10}}>
              <DeleteDeploymentButton variant={"simple"} deployment={deployment} reloadAccount={reloadAccount}/>
          </div>
        </Flex>
      </div>
  )
}

export default Index;
