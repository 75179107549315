import React, {useState} from "react";
import {Text, HStack, Center, Button} from "@chakra-ui/react";
import {FaApple} from "react-icons/fa";
import {FaAws} from "react-icons/fa";
import {FaUbuntu} from "react-icons/fa";
import { VscAzure } from "react-icons/vsc";
import {post} from "../../../client/client";
import {withAccount} from "../../../contexts";
import {useNavigate} from "react-router-dom";


const DeploymentCard = ({title, description, icon, selected, onClick, disabled}: {
  title: string,
  description: string,
  icon: any,
  onClick: () => void,
  selected?: boolean,
  disabled?: boolean
}) => {
  const strongColor = selected ? "white" : "black";
  const color = selected ? "white" : "gray.400";

  const backgroundColor = selected ? (disabled ? "var(--chakra-colors-gray-400)" : "rgb(88, 210, 199)") : "rgba(255, 255, 255, 0.6)";

  return (
      <div style={{width: "300px"}}>
        <Center>
          <Center style={{
            borderRadius: "8px",
            borderWidth: "1px",
            padding: "15px 30px",
            height: "320px",
            width: "410px",
            backgroundColor
          }} role={disabled ? "" : "button"} onClick={disabled ? () => {
          } : onClick}>
            <div>
            <Center>
              {icon}
            </Center>

            <Center style={{marginTop: "10px"}}>
              <Text fontWeight={"bold"} color={strongColor} fontSize={20}>{title}</Text>
            </Center>
            <Center>
              <Text color={color} textAlign={"center"} fontSize={15}>{description}</Text>
            </Center>
              </div>
          </Center>
        </Center>
      </div>
  )
}


const ChooseDeploymentType = ({reloadAccount, databaseType}: {reloadAccount: () => Promise<void>, databaseType: string}) => {

  const navigate = useNavigate();
  const [deploymentType, setDeploymentType] = useState("self-hosted");
  const [creatingDeployment, setCreatingDeployment] = useState(false);

  const createDeployment = () => {
    setCreatingDeployment(true);
    post("/deployments", {deploymentType, databaseType}).then((res) => {
      setCreatingDeployment(false);
      console.log("created deployment", res.data.id);
      reloadAccount();
      navigate(`${res.data.id}`);
    });
  }
  return (
      <>
        <div style={{
          display: "flex",
          justifyContent: "center",
          height: "inherit",
          width: "100%",
          zIndex: 50
        }}>
          <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
            <div>
              <div style={{display: "flex", justifyContent: "center", marginBottom: "40px"}}>
                <Text fontSize='2xl' color={"gray.600"} fontWeight={"bold"}>
                  Choose Deployment Type
                </Text>
              </div>
              <HStack spacing={4} style={{paddingBottom: "5px"}} justify={"space-evenly"}>
                <DeploymentCard onClick={() => setDeploymentType("self-hosted")}
                                title={"Self Hosted Ubuntu / Mac"}
                                selected={deploymentType === "self-hosted"}
                                icon={<div style={{display: "flex"}}>
                                  <FaUbuntu size={100}/><div style={{fontSize: "50pt", marginLeft: "12px"}}>/</div><FaApple size={100}/>
                </div>}
                                description={"A one liner install to run on a server you provide. The configuration of the Epsio instance will be done in your web account."}/>
                <DeploymentCard onClick={() => setDeploymentType("aws-cloudformation")}
                                selected={deploymentType === "aws-cloudformation"}
                                title={"AWS Cloudformation"} icon={<FaAws size={100}/>}
                                description={"For AWS customers, this is a one click install which will deploy a machine of a size you choose within your AWS account."}/>
                <DeploymentCard onClick={() => setDeploymentType("azure-arm")}
                                selected={deploymentType === "azure-arm"}
                                title={"Azure ARM"} icon={<VscAzure size={100}/>}
                                description={"For Azure customers, this is a one click install which will deploy a machine of a size you choose within your Azure account."}/>
              </HStack>
            </div>

            <div style={{display: "flex", justifyContent: "center", marginTop: "40px"}}>
              <Button colorScheme={"blue"} isLoading={creatingDeployment} onClick={createDeployment}>Continue</Button>
            </div>
          </div>

        </div>
      </>
  )
}

export default withAccount(ChooseDeploymentType);