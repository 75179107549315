import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Center,
    Flex, FormControl,
    FormLabel,
    Heading,
    Spacer,
    theme,
    Select,
    useClipboard,
} from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import React, {useEffect, useState} from "react";
import {Elements} from '@stripe/react-stripe-js';

import CardSetupForm from './CardSetupForm';
import {loadStripe} from '@stripe/stripe-js';
import {Input, InputGroup, InputRightElement} from '@chakra-ui/react'
import {Text} from '@chakra-ui/react'
import {Account} from "../../types";
import moment from "moment";
import {CopyIcon, ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import {get, post} from "../../client/client";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

const Settings = ({account, reloadAccount, updateAccount}: {
    account: Account,
    reloadAccount: any,
    updateAccount: any
}) => {
    const [removeLoading, setRemoveLoading] = useState(false);
    const [datadogApiKey, setDatadogApiKey] = useState('');
    const [datadogSite, setDatadogSite] = useState('');
    const [mfaModalIsOpen, setMfaModalIsOpen] = useState(false);
    const [mfaQrCode, setMFAQrCode] = useState<string>("");
    const [mfaSecret, setMFASecret] = useState<string>("");
    const [mfaVerificationCode, setMFAVerificationCode] = useState<string>("");
    const [mfaError, setMfaError] = useState<string | null>(null);
    const [loadingQR, setLoadingQR] = useState(false);
    const [loadingEnablingMFA, setLoadingEnablingMFA] = useState(false);

    const gap = theme.space[5];

    const userToken = account.token || '';
    const userDatadogApiKey = account.datadogApiKey || '';

    const onClose = () => setMfaModalIsOpen(false);
    const onOpen = () => setMfaModalIsOpen(true);

    useEffect(() => {
        if (account.datadogApiKey) {
            setDatadogApiKey(account.datadogApiKey);
        }
        if (account.datadogSite) {
            setDatadogSite(account.datadogSite);
        }
    }, [account]);

    const fetchTotpSecret = async () => {
        try {
            return await get("/totp-secret")
        } catch (error) {
            console.error(error)
            throw error
        }
    };

    const setTotpSecret = async () => {
        try {
            return await post("/totp-secret", {
                secret: mfaSecret,
                code: mfaVerificationCode
            })
        } catch (error) {
            console.error(error)
            throw error
        }
    };

    const removeCard = () => {
        setRemoveLoading(true);
        updateAccount({...account, cardSetup: false}).then(() => {
            setRemoveLoading(false);
        });
    }

    const saveDatadogInfo = () => {
        updateAccount({...account, datadogApiKey: datadogApiKey, datadogSite: datadogSite}).then(() => {
            reloadAccount();
        });
    }

    const removeDatadogInfo = () => {
        setDatadogSite('');
        setDatadogApiKey('');
        updateAccount({...account, datadogApiKey: null, datadogSite: null}).then(() => {
            reloadAccount();
        });
    }

    const [showToken, setShowToken] = useState(false);
    const {hasCopied, onCopy} = useClipboard(userToken);

    const handleShowClick = () => setShowToken(!showToken);

    const displayValue = (value: string) => {
        return showToken ? value : `${value.slice(0, -4).replace(/./g, '*')}${value.slice(-4)}`;
    };

    const startConfiguringMFA = () => {
        setLoadingQR(true);
        fetchTotpSecret().then(
            (response) => {
                setLoadingQR(false);
                setMFAQrCode(response.data.qr);
                setMFASecret(response.data.secret);
                setMfaModalIsOpen(true);
            }
        ).catch((error) => {
            console.error(error)
        });
    }

    const enableMFA = () => {
        setLoadingEnablingMFA(true);
        setTotpSecret().then(
            (response) => {
                reloadAccount();
                setLoadingEnablingMFA(false);
                setMfaModalIsOpen(false);
            }
        ).catch((response) => {
            setLoadingEnablingMFA(false);
            setMfaError(response.response.data.error)
        });
    }

    return <div style={{height: "100%"}}>
        <Center style={{minHeight: "80%", flexDirection: "column", gap}} pb={gap}>
            <Card style={{minWidth: "60%", minHeight: "20%"}} hidden={userToken === ''}>
                <CardHeader pb={0}>
                    <Heading size='md'>Access Token</Heading>
                </CardHeader>
                <CardBody>
                    <Text as='b'>Copy your access token to create Docker/K8s deployments without the web console</Text>
                    <br/>
                    <br/>

                    <InputGroup size='md'>
                        <Input
                            type='text'
                            value={displayValue(userToken)}
                            isReadOnly
                        />
                        <InputRightElement width='8rem'>
                            <Button size='sm' onClick={handleShowClick} style={{backgroundColor: "transparent"}}>
                                {showToken ? <ViewIcon/> : <ViewOffIcon/>}
                            </Button>
                            <Button size='sm' ml={2} onClick={onCopy} style={{backgroundColor: "transparent"}}>
                                {hasCopied ? 'Copied' : <CopyIcon/>}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </CardBody>
            </Card>

            <Card style={{minWidth: "60%", minHeight: "20%"}} hidden={!account.isMfaEnabled}>
                <CardHeader pb={0}>
                    <Heading size='md'>MFA Enabled <CheckIcon style={{marginLeft: "5px"}} color={"green.500"}/></Heading>
                </CardHeader>
                <CardBody>
                    <Text>MFA is enabled for the current user</Text>
                </CardBody>
            </Card>

            <Card style={{minWidth: "60%", minHeight: "20%"}} hidden={account.isMfaEnabled}>
                <CardHeader pb={0}>
                    <Heading size='md'>Enable MFA for User</Heading>
                </CardHeader>
                <CardBody>
                    <Text as='b'>Enable MFA for your current user</Text>
                    <br/>
                    <br/>
                    <Button colorScheme='blue' isLoading={loadingQR} onClick={startConfiguringMFA}>Enable MFA</Button>

                </CardBody>

                <Modal isOpen={mfaModalIsOpen} onClose={onClose} size={"lg"}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Scan QR Code</ModalHeader>
                        <ModalBody>
                            {mfaQrCode && (
                                <>
                                    <Text>Open your preferred authentication app on your mobile device and scan this QR code.
                                    </Text>
                                    <Image
                                        src={mfaQrCode}
                                        alt="MFA QR Code"
                                        mt={1}
                                        boxSize="250px"
                                        mx="auto"
                                    />
                                    <Text mt={4}>
                                        If you are unable to scan the image, enter the code provided to your app: <b>{mfaSecret}</b>
                                    </Text>
                                    <br/><br/>
                                    <Text>
                                        Once you have scanned the QR code or entered the secret, enter the code from your
                                        authenticator app below:
                                    </Text>
                                    <FormControl mt={4}>
                                        <FormLabel>Code</FormLabel>
                                        <Input value={mfaVerificationCode} onChange={(e) => setMFAVerificationCode(e.target.value)} />
                                    </FormControl>
                                    {
                                        mfaError && (
                                            <Alert status="error" mt={4}>
                                                <AlertIcon />
                                                {mfaError}
                                            </Alert>
                                        )

                                    }
                                </>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onClose} colorScheme="red">
                                Cancel
                            </Button>
                            <Button isDisabled={mfaVerificationCode === ""} style={{marginLeft: "5px"}} onClick={enableMFA} colorScheme="blue" isLoading={loadingEnablingMFA}>
                                Enable
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Card>


            <Card style={{minWidth: "60%", minHeight: "20%"}} hidden={userToken === ''}>
                <CardHeader pb={0}>
                    <Heading size='md'>Datadog Alerts and Monitoring</Heading>
                </CardHeader>
                <CardBody>

                    {
                        account.datadogApiKey ? <><Alert status='info'>
                            Datadog API Key is setup (site&nbsp;{account.datadogSite})
                        </Alert>
                            <br/>
                            <Button colorScheme='red' onClick={removeDatadogInfo}>Remove Datadog API Key</Button>
                        </> : <>
                            <Text as='b'>Configure a Datadog API key and Region to send monitoring data and alerts to
                                your
                                Datadog account</Text>
                            <br/>
                            <br/>

                            <div style={{display: "flex", paddingBottom: "15px"}}>
                                <InputGroup size='md' style={{paddingRight: "10px", flex: "5"}}>
                                    <Input
                                        type='text'
                                        placeholder='API Key'
                                        value={datadogApiKey}
                                        onChange={(e) => setDatadogApiKey(e.target.value)}
                                    />
                                </InputGroup>
                                <div style={{flex: 3}}>
                                    <Select placeholder='Region' value={datadogSite}
                                            onChange={(c) => setDatadogSite(c.target.value)}>
                                        <option value='datadoghq.com'>US1</option>
                                        <option value='us3.datadoghq.com'>US3</option>
                                        <option value='us5.datadoghq.com'>US5</option>
                                        <option value='datadoghq.eu'>EU1</option>
                                        <option value='ddog-gov.com'>US1-FED</option>
                                        <option value='ap1.datadoghq.com'>AP1</option>
                                    </Select>
                                </div>
                            </div>
                            <Button colorScheme='blue' mt={2} onClick={saveDatadogInfo}
                                    isDisabled={datadogSite === "" || datadogSite === null || datadogApiKey === ""}>Save</Button>
                        </>
                    }
                </CardBody>
            </Card>
            <Card style={{minWidth: "60%", minHeight: "30%"}}>
                <CardHeader pb={0}>
                    <Heading size='md'>Billing</Heading>
                </CardHeader>
                <CardBody>
                    {
                        account.cardSetup ? <><Alert status='info'>
                            Your next billing date is&nbsp;
                            <b>{moment(account.nextBillingDate).format('MMM DD, YYYY')}</b>
                        </Alert>
                            <br/>
                            <Button colorScheme='red' onClick={removeCard}>Remove card</Button>
                        </> : <>
                            <Text as='b'>Add a credit card to continue using Epsio after your free trial</Text>
                            <br/>
                            <br/>
                            <Elements stripe={stripePromise}>
                                {/* @ts-ignore*/}
                                <CardSetupForm account={account} updateAccount={updateAccount}
                                               reloadAccount={reloadAccount}/>
                            </Elements>
                        </>
                    }
                </CardBody>
            </Card>
        </Center>
    </div>
}

export default Settings;
